<template>
  <div class="chart flex-grow" :style="{'minHeight': height}" v-if="props.repeatedMeasures !== null && props.repeatedMeasures.length > 0">
      <horizontal-bar-chart
          :data="getChartData()"
          :display-legend="false"
          :height="height"
          :options="getChartOptions()"
          :plugins="[ChartDataLabels]"
      ></horizontal-bar-chart>
    <CardSpinner v-if="userStore.loaded.recentRepeatedMeasuresFetching" />
  </div>
  <div v-else-if="!userStore.loaded.recentRepeatedMeasuresFetching">
    <p class="text-center">No recent repeated measures found.</p>
  </div>
</template>

<script setup lang="ts">
import type { RecentRepeatedMeasure } from '@/types/RecentRepeatedMeasure'
import HorizontalBarChart from "@/components/HorizontalBarChart.vue";
import type { Chart, ChartData, ChartOptions, TooltipModel, TooltipItem } from "chart.js";
import {useOrganizationStore} from "@/stores/OrganizationStore";
import { computed } from "vue";
import moment from "moment/moment";
import CardSpinner from "@/components/CardSpinner.vue";
import { useUserStore } from "@/stores/UserStore";
import ChartDataLabels from "chartjs-plugin-datalabels";

export interface Props {
  repeatedMeasures: RecentRepeatedMeasure[] | null
}

const userStore = useUserStore()
const organizationStore = useOrganizationStore()
const props = withDefaults(defineProps<Props>(), {
    repeatedMeasures: null,
})
const height = computed(() => {
  return (props.repeatedMeasures?.length ?? 5) * 35 + 82 + 'px'
})

function getChartData(): ChartData<'bar'> {
    let barColors: string[] = []
    let dataset: number[] = []
  let repeatedMeasureData = props.repeatedMeasures
    if (repeatedMeasureData !== null && repeatedMeasureData.length > 0) {
        dataset = repeatedMeasureData.map((repeatedMeasure: RecentRepeatedMeasure) => {
            return (repeatedMeasure.score / 100.00) as number
        })

        barColors = repeatedMeasureData.map(() => {
            return organizationStore.getCurrentOrganizationTheme().secondaryBrandColor;
        })
    }
    let labels = repeatedMeasureData?.map((repeatedMeasure: RecentRepeatedMeasure) => {
        return formatRepeatedMeasureLabels(repeatedMeasure.initialTest.display, repeatedMeasure.finalTest.display);
    })

    return {
        labels: labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: barColors
          }
        ],
    }
}

function formatRepeatedMeasureLabels(label1: string, label2: string): string[] | string {
    const MAX_LENGTH = 30;

    label1 = label1.replace('(Repeated Measure)', '').trim();
    label2 = label2.replace('(Repeated Measure)', '').trim();

    const truncate = (str: string) => (str.length > MAX_LENGTH ? str.slice(0, MAX_LENGTH - 3) + "..." : str);

    const truncated1 = truncate(label1);
    const truncated2 = truncate(label2);

    if (truncated1 === truncated2) {
        return [truncated1];
    } else {
        return [truncated1, truncated2];
    }
}

function getChartOptions(): ChartOptions<'bar'> {
  return {
    barPercentage: 0.8,
    categoryPercentage: 0.8,
    plugins: {
      tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler,
      }
    }
  } as ChartOptions<'bar'>
}

const getOrCreateTooltip = (chart: Chart) => {
    let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = '1';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart?.canvas?.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context: { chart: Chart, tooltip: TooltipModel<'bar'> }) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    // Set Text
    if (tooltip.body) {
        const dataIndex = tooltip.dataPoints[0].dataIndex;
        const repeatedMeasureData = props.repeatedMeasures;

        if (repeatedMeasureData) {

            const titleLines = [`Score: ${repeatedMeasureData[dataIndex].score}`];
            const bodyLines = [
                `<span style="color: var(--ych-kraft-light); font-weight: bold;">Panels:</span> ${repeatedMeasureData[dataIndex].initialTest.panelName} - ${repeatedMeasureData[dataIndex].finalTest.panelName}`,
                `<span style="color: var(--ych-kraft-light); font-weight: bold;">Tests:</span> ${repeatedMeasureData[dataIndex].initialTest.display} - ${repeatedMeasureData[dataIndex].finalTest.display}`,
                `<span style="color: var(--ych-kraft-light); font-weight: bold;">Dates:</span> ${moment(repeatedMeasureData[dataIndex].initialTest.testDate).format('MMM D, YYYY')} - ${moment(repeatedMeasureData[dataIndex].finalTest.testDate).format('MMM D, YYYY')}`,
            ];

            const tableHead = document.createElement('thead');
            titleLines.forEach((title) => {
                const tr = document.createElement('tr');
                const th = document.createElement('th');
                th.style.color = 'var(--ych-kraft-light)';
                th.style.fontWeight = 'bold';
                th.appendChild(document.createTextNode(title));
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body) => {
                const tr = document.createElement('tr');
                const td = document.createElement('td');
                td.innerHTML = body; // Use innerHTML to apply the span styling
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            if (tableRoot) {

                // Remove old children
                while (tableRoot.firstChild) {
                    tableRoot.firstChild.remove();
                }

                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
            }
        }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = 'normal normal 12px Roboto';
    tooltipEl.style.padding = `${tooltip.options.padding}px`;
};


</script>

<style scoped lang="scss">
.chart {
    .chart__label {
        color: var(--ych-grey-400);
    }

    &__legend {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        gap: 20px;

        &__color {
            height: 15px;
            width: 40px;

            display: inline-block;

            &.primary {
                background-color: var(--secondary-brand-color);
            }
        }
    }
}
</style>
