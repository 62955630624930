<template>
  <div class="stats-metrics" v-if="metrics">
    <div class="title-row">
      <h2 class="mb-4">Your Stats & Metrics</h2>
      <user-validations v-if="metrics" :metrics="metrics"></user-validations>
    </div>
    <div class="grid grid-cols-1 xl:grid-cols-3 xl:divide-x">
      <div class="grid col-span-2 xl:pr-3 xl:divide-y">
        <leaderboard-status v-if="metrics" :metrics="metrics"></leaderboard-status>
        <div class="reference-standards grid col-span-1 xl:pl-3 py-3 gap-6 content-start divide-color">
          <h3>Recent Repeated Measure Scores</h3>
          <div class="flex flex-col py-3 gap-4">
            <recent-repeated-measures :repeatedMeasures="recentRepeatedMeasures" ></recent-repeated-measures>
          </div>
        </div>
      </div>
      <div class="reference-standards grid col-span-1 xl:pl-3 py-3 gap-6 content-start divide-color">
        <h3 class="text-center">Reference Standards</h3>
        <reference-standards title="Top 5" :references="topReferences"></reference-standards>
        <reference-standards title="Bottom 5" :references="bottomReferences"></reference-standards>
      </div>
    </div>
    <div class="w-full flex justify-center">
      <RouterLink to="/profile">View Full Profile & Metrics</RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/UserStore'
import { computed, onMounted, ref } from 'vue'
import ReferenceStandards from '@/components/ReferenceStandards.vue'
import AromaSelectionChart from '@/components/StatsAndMetrics/AromaSelectionChart.vue'
import LeaderboardStatus from '@/components/StatsAndMetrics/LeaderboardStatus.vue'
import UserValidations from '@/components/StatsAndMetrics/UserValidations.vue'
import RecentRepeatedMeasures from "@/components/UserSchedule/RecentRepeatedMeasures.vue";

const userStore = useUserStore()

userStore.fetchUserMetrics()
userStore.fetchUserReferenceStandards()
userStore.fetchUserRecentRepeatedMeasures()

const metrics = computed(() => {
  return userStore.currentUserMetrics
})

// ReferenceStandard Standard Variables
const topReferences = computed(() => {
  return userStore.userTopReferenceStandards
})
const bottomReferences = computed(() => {
  return userStore.userBottomReferenceStandards
})
const recentRepeatedMeasures = computed(() => {
    return userStore.userRecentRepeatedMeasures
})
</script>

<style scoped lang="scss">
.divide-color {
  border-color: var(--ych-grey-200);
  border-style: solid;
}

.title-row {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.reference-standards {
  &__list {
    counter-reset: referenceCounter;
    padding-left: 1.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      counter-increment: referenceCounter;
      position: relative;
      font-size: 1rem;
      font-family: var(--y-font-family-condensed);
      line-height: 1.25rem;

      &::before {
        content: counter(referenceCounter);
        color: var(--primary-accent-color);
        background: var(--secondary-brand-color);
        width: 1.25rem;
        height: 1.25rem;
        font-family: var(--y-font-family-condensed);
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: var(--y-font-weight-bold);
        border-radius: 50%;
        text-align: center;
        position: absolute;
        left: -1.75rem;
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>
